import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/Seo'
import Hero from '../components/Resources/Hero'
import FAQs from '../components/Resources/FAQs'
import Video from '../components/Resources/Video'
import Cards from '../components/Resources/Cards'
import CustomerService from '../components/Resources/CustomerService'

const Resources = ({ data }) => {
  const {
    seoTitle,
    seoDescriptions,
    slug,
    heroText: { heroText },
    heroImage,
    faQs,
    videoText: { videoText },
    videoId,
    resources,
    customerServiceTitle,
    customerServiceText,
    customerServiceImage,
  } = data.contentfulResources

  return (
    <>
      <Seo
        title={seoTitle}
        description={seoDescriptions?.seoDescriptions}
        pathname={slug}
      />

      <Hero text={heroText} image={heroImage} />

      <FAQs items={faQs} />

      <Video text={videoText} videoId={videoId} />

      <Cards items={resources} />

      <CustomerService
        title={customerServiceTitle}
        text={customerServiceText}
        image={customerServiceImage}
      />
    </>
  )
}

export default Resources

export const pageQuery = graphql`
  query Resources($slug: String!) {
    contentfulResources(slug: { eq: $slug }) {
      seoTitle
      seoDescriptions {
        seoDescriptions
      }
      seoImage {
        gatsbyImageData
      }
      slug
      heroText {
        heroText
      }
      heroImage {
        gatsbyImageData(quality: 100)
        description
      }
      faQs {
        ... on Node {
          ...ContentfulListItems
        }
      }
      videoText {
        videoText
      }
      videoId
      resources {
        metadata {
          tags {
            name
          }
        }
        title
        excerpt {
          excerpt
        }
        slug
      }
      customerServiceTitle
      customerServiceText {
        raw
      }
      customerServiceImage {
        gatsbyImageData(quality: 100)
        description
      }
    }
  }
`
