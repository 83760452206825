import React from 'react'
import PropTypes from 'prop-types'
import {
  // FAQsDots,
  // FAQsDotsInner,
  FAQsItems,
  FAQsMain,
  FAQsTitle,
} from './index.style'
import RoundedBackground from '../../RoundedBackground'
import Container from '../../Container'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import Spacer from '../../Spacer'
import { Heading2, Heading4 } from '../../TextStyles'
import AnimateSplitText from '../../animation/AnimateSplitText'
import Accordion from '../../Accordion'
import RichText from '../../RichText'
// import Dots from '../../Dots'

const FAQs = ({ items }) => (
  <FAQsMain>
    <RoundedBackground />

    <Container>
      <Spacer size={[80, 180]} />

      <Grid>
        <GridItem tabletP={12} tabletPStart={3} tabletL={6} tabletLStart={2}>
          <FAQsTitle>
            <Heading2>
              <AnimateSplitText>FAQ</AnimateSplitText>
            </Heading2>

            {/* <FAQsDots>
              <FAQsDotsInner>
                <Dots />
              </FAQsDotsInner>
            </FAQsDots> */}
          </FAQsTitle>
        </GridItem>

        <GridItem tabletP={12} tabletPStart={3} tabletL={7} tabletLStart={9}>
          <FAQsItems>
            {React.Children.toArray(
              items.map((item, itemIndex) => (
                <Accordion
                  openStart={itemIndex === 0 ? true : false}
                  toggle={
                    <Heading4 as="span">
                      <AnimateSplitText>{item.title}</AnimateSplitText>
                    </Heading4>
                  }
                >
                  <RichText content={item.richContent} />
                </Accordion>
              ))
            )}
          </FAQsItems>
        </GridItem>
      </Grid>

      <Spacer size={[80, 180]} />
    </Container>
  </FAQsMain>
)

FAQs.propTypes = {
  items: PropTypes.array.isRequired,
}

export default FAQs
