import React from 'react'
import PropTypes from 'prop-types'
import { HeroContainer, HeroImage, HeroMain } from './index.style'
import Spacer from '../../Spacer'
import DotLabel from '../../DotLabel'
import Container from '../../Container'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import { Heading2 } from '../../TextStyles'
import AnimateSplitText from '../../animation/AnimateSplitText'
import AnimateFadeIn from '../../animation/AnimateFadeIn'
import { GatsbyImage } from 'gatsby-plugin-image'
import SearchBar from '../../SearchBar'

const Hero = ({ text, image }) => (
  <HeroContainer>
    <Container>
      <Grid>
        <GridItem tabletP={9} tabletL={7} tabletLStart={2}>
          <HeroMain>
            <DotLabel>Resources</DotLabel>

            <Spacer size={[32, 64]} />

            <Heading2 as="h1">
              <AnimateSplitText delay={0.1}>{text}</AnimateSplitText>
            </Heading2>

            <Spacer size={[40, 90]} />

            <AnimateFadeIn delay={0.2}>
              <SearchBar to="/resources/search" label="Need help?" />
            </AnimateFadeIn>
          </HeroMain>
        </GridItem>

        <GridItem tabletP={7} tabletL={6} tabletLStart={10}>
          <HeroImage>
            <AnimateFadeIn>
              <GatsbyImage
                image={image.gatsbyImageData}
                alt={image.description}
              />
            </AnimateFadeIn>
          </HeroImage>
        </GridItem>
      </Grid>

      <Spacer size={[85, 200]} />
    </Container>
  </HeroContainer>
)

Hero.propTypes = {
  text: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
}

export default Hero
