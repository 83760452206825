import styled from 'styled-components'
import { clamp } from '../../../styles/utils/conversion.style'
import { breakpoints } from '../../../styles/vars/breakpoints.style'
import { mq } from '../../../styles/vars/media-queries.style'

export const HeroContainer = styled.div`
  ${mq.deskMMax} {
    ${clamp('padding-top', 170, 210, breakpoints.mobile, breakpoints.deskM)}
  }

  ${mq.deskM} {
    ${clamp('padding-top', 210, 300, breakpoints.deskM, breakpoints.deskL)}
  }
`

export const HeroMain = styled.div`
  padding-bottom: 6.5rem;
`

export const HeroImage = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`
