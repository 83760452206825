import React from 'react'
import PropTypes from 'prop-types'
import { CustomerServiceContent } from './index.style'
import Spacer from '../../Spacer'
import Container from '../../Container'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Heading4 } from '../../TextStyles'
import AnimateSplitText from '../../animation/AnimateSplitText'
import AnimateFadeIn from '../../animation/AnimateFadeIn'
import RichText from '../../RichText'

const CustomerService = ({ title, text, image }) => (
  <>
    <Spacer size={[60, 160]} />

    <Container>
      <Grid>
        <GridItem mobileL={7} tabletL={7} tabletLStart={2}>
          <AnimateFadeIn>
            <GatsbyImage
              image={image.gatsbyImageData}
              alt={image.description}
            />
          </AnimateFadeIn>
        </GridItem>

        <GridItem mobileL={7} tabletL={5}>
          <CustomerServiceContent>
            <Heading4>
              <AnimateSplitText>{title}</AnimateSplitText>
            </Heading4>

            <Spacer size={[20, 30]} />

            <RichText content={text} />
          </CustomerServiceContent>
        </GridItem>
      </Grid>
    </Container>

    <Spacer size={[100, 130]} />
  </>
)

CustomerService.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.object.isRequired,
  image: PropTypes.object.isRequired,
}

export default CustomerService
